import { Component, OnInit, Self } from '@angular/core';
import { NgOnDestroy } from '@core/services/unsubscribe.service';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'prtl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NgOnDestroy]
})
export class HeaderComponent implements OnInit {
  public currentPage = '';

  constructor(
    @Self() private readonly ngOnDestroy$: NgOnDestroy,
    private router: Router
  ) {
    this.switchThrough(this.router.url);
  }

  ngOnInit(): void {
    this.router.events.pipe(
      takeUntil(this.ngOnDestroy$),
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.switchThrough(event.urlAfterRedirects);
    });
  }

  private switchThrough(value: string): void {
    console.log(`switchThrough: ${value}`);
    if (value.match('/admin')) {
      this.currentPage = 'SIDEBAR.ADMIN';
    } else if (value.match('/customers')) {
      this.currentPage = 'SIDEBAR.CUSTOMERS';
    } else if (value.match('/customer')) {
      this.currentPage = 'SIDEBAR.CUSTOMER';
    } else if (value.match('/discoveries')) {
      this.currentPage = 'SIDEBAR.DISCOVERIES';
    } else if (value.match('/findings')) {
      this.currentPage = 'SIDEBAR.FINDINGS';
    } else if (value.match('/rules')) {
      this.currentPage = 'SIDEBAR.RULES';
    } else if (value.match('/srdiscussion')) {
      this.currentPage = 'SIDEBAR.SRDISCUSSION';
    } else if (value.match('/uploadSupplemental')) {
      this.currentPage = 'SIDEBAR.UPLOAD_SUPPLEMENTAL';
    }
  }
}
