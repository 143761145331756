import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
  private baseUrl = environment.requestUrl;
  private assessmentUrl = environment.assessmentUrl;

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/assets/')) {
      return next.handle(req);
    }
    if ( req.url.startsWith('/analyzers')
      || req.url.startsWith('/applications')
      || req.url.startsWith('/categories')
      || req.url.startsWith('/discoveries')
      || req.url.startsWith('/findings')
      || req.url.startsWith('/forms')
      || req.url.startsWith('/reprocess')
      || req.url.startsWith('/rules')
      || req.url.startsWith('/sr')
      || req.url.startsWith('/workflows')
    ) {
      const apiReq = req.clone({url: `${this.assessmentUrl}${req.url}`});
      return next.handle(apiReq);
    }
    const apiReq = req.clone({url: `${this.baseUrl}${req.url}`});
    return next.handle(apiReq);
  }
}
